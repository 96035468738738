









































import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import Client from "@/models/Client";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import ClientService from "@/services/ClientService";
import PotentialClient from "@/models/PotentialClient";
import PotentialClientService from "@/services/PotentialClientService";
import LangModule from "@/store/LangModule";

@Component
export default class PotentialClientTab extends Vue {
	@Prop() readonly potentialClient!: PotentialClient
	@Ref() readonly form!: HTMLFormElement
	@Prop() refresh!: any

	lang: any = getModule(LangModule).lang


	updatePotentialClient() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.operationConfirmation, () => PotentialClientService.patchPotentialClient(this, this.potentialClient, this.potentialClient.id!)
		))
	}
}
