






















































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Address, {AddressType, AddressSelect, AddressParentTypes} from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";
import AddressDialog from "@/components/dialog/AddressDialog.vue";
import CreateAddressDialog from "@/components/dialog/CreateAddressDialog.vue";

@Component({
  computed: {
    AddressParentTypes() {
      return AddressParentTypes
    }
  },
  components: { AddressDialog, CreateAddressDialog } })
export default class AddressTab extends Vue {

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Prop() readonly client!: Client;
  dialog: boolean = false;
  addressDialog: boolean = false
  loading: boolean = false
  address: Address = new Address()
  addresses: Address[] = [];
  headers = [
		{ text: this.lang.name, value: "name", width: "auto", align: "center" },
		{ text: this.lang.name, value: "email", width: "auto", align: "center" },
		{ text: this.lang.address, value: "address", width: "auto", align: "center" },
		{ text: this.lang.postalCode, value: "postalCode", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" },
  ]
  minLength:number = 4
  maxLength:number = 250
  //validations for each form field
  basicRules = [
    (input: string) => (input ? true : this.lang.pleaseFillField),
    (input: string) => input.length >= this.minLength ? true : `El campo debe ser de minimo ${this.minLength} caracteres`,
    (input: string) => input.length <= this.maxLength ? true : `El campo debe ser de minimo ${this.maxLength} caracteres`,
  ]

  created() {
    this.refresh()
  }

  refresh() {
    AddressService.getAddressByClient(this, this.addresses, this.client.id!)
  }

  addressDetail(address: Address) {
    this.addressDialog = true;
    this.address = address;
  }

  closeDetail() {
    this.addressDialog = false;
    this.address = new Address();
  }

  deleteAddress(item: Address) {
    getModule(DialogModule).showDialog(
      new Dialog(this.lang.warning, this.lang.deleteAddressConfirmation, () =>
        AddressService.deleteAddress(this, item.id!)
      )
    );
  }

  createAddress() {
    if (this.form.validate()) {
      getModule(DialogModule).showDialog(
        new Dialog(this.lang.warning, this.lang.createAddressConfirmation, () =>
          AddressService.postAddress(this, this.address, this.client.id!)
        )
      )
    }
  }
}
