import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import SnackbarModule from "@/store/SnackbarModule";
import JsonTool from "@/services/tool/JsonTool";
import ClientRegister from "@/models/ClientRegister";

export default class ClientRegisterService {

    static async getClientRegister(component: Vue, clientRegisters: ClientRegister[], userId: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/users/" + userId + "/client-registers", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, ClientRegister)
            clientRegisters.splice(0, clientRegisters.length)
            list.forEach(p => clientRegisters.push(p))
            // @ts-ignore
            component.loading = false
        } catch (err) {
            // @ts-ignore
            component.loading = false
            getModule(SnackbarModule).makeToast("Error al obtener los registros")
        }
    }

}