import Auditing from "@/models/Auditing";
import {JsonObject, JsonProperty} from "json2typescript";
import User from "@/models/User";
import Client from "@/models/Client";

@JsonObject("ClientRegister")
export default class ClientRegister extends Auditing {
    @JsonProperty("id", Number, true)
    id?: number = undefined
    @JsonProperty("usedBy", User, true)
    usedBy?: User = undefined
    @JsonProperty("client", Client, true)
    client?: Client = undefined
}