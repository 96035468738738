



















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Address, { AddressType, AddressSelect } from "@/models/Address";
import AddressService from "@/services/AddressService";
import { getModule } from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import Client from "@/models/Client";
import StringTool from "@/services/tool/StringTool";
import LangModule from "@/store/LangModule";

@Component
export default class AddressDialog extends Vue {

	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	@Prop() readonly client!: Client
	@Prop() addressId!: number

	dialog: boolean = false;
	detailDialog: boolean = false
	loading: boolean = false
	address: Address = new Address()
	addresses: Address[] = []
	headers = [
		{ text: "Id", value: "id", width: "auto", align: "center" },
		{ text: this.lang.address, value: "address", width: "auto", align: "center" },
		{ text: this.lang.actions, value: "actions", width: "auto", align: "center" },
	];
	minLength:number = 4
	maxLength:number = 250
	//validations for each form field
	basicRules = [
		(input: string) => (input ? true : this.lang.requiredField),
		(v: string) => v.length >= this.minLength ? true : `El campo debe ser de minimo ${this.minLength} caracteres`,
		(v: string) => v.length <= this.maxLength ? true : `El campo debe ser de maximo ${this.maxLength} caracteres`,
	]

	namesRules:((input: string) => string | true)[] = this.basicRules
	addressRules:((input: string) => string | true)[] = this.basicRules

	rules = {
		required: (input: string) => (input ? true : this.lang.requiredField),
		email: (input: string) => StringTool.validateEmail(input) ? true : this.lang.emailNoValid,
		postalCode: (input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
	}

	created() {
		this.refresh()
	}

	refresh() {
		AddressService.getAddress(this, this.addressId)
	}

	closeDetail() {
		this.detailDialog = false
		this.$emit("close")
	}

	deleteAddress(item: Address) {
		getModule(DialogModule).showDialog(
			 new Dialog(this.lang.warning, this.lang.deleteAddressConfirmation, () =>
					AddressService.deleteAddress(this, item.id!)
			 )
		);
	}

	createAddress() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(
				 new Dialog(this.lang.warning, this.lang.createAddressConfirmation, () =>
						AddressService.postAddress(this, this.address, this.client.id!)
				 )
			)
		}
	}

	updateAddress() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(
				 new Dialog(
						this.lang.warning, this.lang.updateAddressConfirmation, async () => {
							await AddressService.patchAddress(this, this.address)
					 		this.$emit("close")
							this.$emit("refresh")
						}
				 )
			)
		}
	}

}
